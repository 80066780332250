<template>
  <div aria-hidden="true" class="gradient"></div>
</template>

<style lang="scss" scoped>
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.gradient {
  --size: calc(100vw - 10rem);
  --speed: 10s;
  --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);

  width: var(--size);
  height: var(--size);
  filter: blur(calc(var(--size) / 5));
  background-image: linear-gradient(#229acf, #b5c430);
  animation: rotate var(--speed) var(--easing) alternate infinite;
  border-radius: 50% 50% 50% 50% / 50% 50% 50% 50%;
}

@media (min-width: 480px) {
  .gradient {
    --size: calc(100vw - 20rem);
  }
}

@media (min-width: 1024px) {
  .gradient {
    --size: calc(100vw - 30rem);
  }
}

@media (min-width: 1280px) {
  .gradient {
    --size: calc(100vw - 50rem);
  }
}

@media (min-width: 1536px) {
  .gradient {
    --size: calc(100vw - 60rem);
  }
}

@media (min-width: 1920px) {
  .gradient {
    --size: 60rem;
  }
}
</style>
